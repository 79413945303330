export type DisplayFromUrlQueryT = {
  dataAttrName?: string
  hideValue?: string
  showValue?: string
  showDisplayValue?: "block" | "flex" | "grid" | "inline" | "inline-block"
  query: string
}

export function displayFromUrlQuery({
  dataAttrName = "data-display-from-url-query",
  hideValue = "hide",
  showValue = "show",
  showDisplayValue = "block",
  query,
}: DisplayFromUrlQueryT) {
  // Get the current URL
  const currentUrl = window.location.href

  // Create a URL object
  const urlObj = new URL(currentUrl)

  // Get the search parameters from the URL
  const searchParams = new URLSearchParams(urlObj.search)

  // Check if the specific query parameter exists
  if (searchParams.has(query)) {
    // Get all elements that match the 'hide' query
    const hideElements = document.querySelectorAll(`[${dataAttrName}="${hideValue}"]`)
    // Get all elements that match the 'show' query
    const showElements = document.querySelectorAll(`[${dataAttrName}="${showValue}"]`)

    // For each hide elements, add a display=none style
    hideElements.forEach((element) => {
      const elementEl = element as HTMLElement
      elementEl.style.display = "none"
    })

    // For each show elements, add a display=[showDisplayValue] style
    showElements.forEach((element) => {
      const elementEl = element as HTMLElement
      elementEl.style.display = showDisplayValue
    })
  }
}
