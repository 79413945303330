if (process.env.NODE_ENV === "development") {
  console.log("Running utils")
}

import {displayFromUrlQuery, DisplayFromUrlQueryT} from "./display-from-url-query"
import {setDomElementFromUrl, DomFromUrlT} from "./dom-from-url"
import {
  hideAllEmptyCollectionContainers,
  HideEmptyCollectionT,
} from "./hide-empty-collection-container"
import PageTransitions from "./page-transitions"
import {staggerInteractions, StaggerInteractionsT} from "./stagger-interactions"
import {removeItems, RemoveItemsT} from "./remove-items-from-dom"

interface CustomWindow extends Window {
  GoatUtils: {
    displayFromUrlQuery: (args: DisplayFromUrlQueryT) => void
    hideAllEmptyCollectionContainers: (args: HideEmptyCollectionT) => void
    PageTransitions: typeof PageTransitions
    removeItems: (args: RemoveItemsT) => void
    setDomElementFromUrl: (args: DomFromUrlT) => Promise<void>
    staggerInteractions: (args: StaggerInteractionsT) => void
  }
}

declare var window: CustomWindow

/**
 * @description Expose utils to window
 */
window.GoatUtils = {
  displayFromUrlQuery,
  hideAllEmptyCollectionContainers,
  PageTransitions,
  removeItems,
  setDomElementFromUrl,
  staggerInteractions,
}
