export type DomFromUrlT = {
  url: string
  dataAttrName?: string
  fetchValue?: string
  targetValue?: string
}

function getElement(dom: string, selector: string) {
  try {
    const parser = new DOMParser()
    const doc = parser.parseFromString(dom, "text/html")
    return doc.querySelector(selector)
  } catch (_) {
    console.warn(`Could not parse ${dom}`)
    return null
  }
}

export async function getDomElementFromUrl(url: string, selector: string): Promise<Element | null> {
  try {
    const response = await fetch(url)
    if (!response.ok) {
      console.warn(`Could not fetch ${url}`)
      return null
    }
    const text = await response.text()
    return getElement(text, selector)
  } catch (_) {
    console.warn(`Could not fetch ${url}`)
    return null
  }
}

export async function setDomElementFromUrl({
  url,
  dataAttrName = "data-dom-from-url",
  fetchValue = "fetch-element",
  targetValue = "target-element",
}: DomFromUrlT) {
  if (process.env.NODE_ENV === "development") {
    console.log("Running fetch DOM element from URL")
  }

  const fetch = `[${dataAttrName}="${fetchValue}"]`
  const target = `[${dataAttrName}="${targetValue}"]`
  const fetchEl = await getDomElementFromUrl(url, fetch)
  if (!fetchEl) {
    console.warn(`Could not find element with selector ${fetch} in ${url}`)
    return
  }
  const targetEl = document.querySelector(target)
  if (!targetEl) {
    console.warn(`Could not find target with selector ${target}`)
    return
  }
  targetEl.innerHTML = ""
  targetEl.appendChild(fetchEl)
}
