export type RemoveItemsT = {
  attr?: string
}

export function removeItems({attr = ".w-condition-invisible"}: RemoveItemsT) {
  if (process.env.NODE_ENV === "development") {
    console.log("Running remove item from DOM")
  }

  const items = Array.from(document.querySelectorAll(attr)) as HTMLElement[]

  items.map((item) => {
    item.remove()
  })
}
