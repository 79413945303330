export function safeParseInt(value: string, defaultValue: number): number {
  try {
    const parsed = parseInt(value);
    if (isNaN(parsed)) {
      return defaultValue;
    }
    return parsed;
  } catch (e) {
    return defaultValue;
  }
}
