export type StaggerInteractionsT = {
  dataAttrName?: string
  element?: string
  triggerOn?: "load" | "click"
  trigger?: string
  initialDelay?: number
  delayBetween?: number
}

export function staggerInteractions({
  dataAttrName = "data-stagger-interaction",
  element = "element",
  triggerOn = "load",
  trigger,
  initialDelay = 0,
  delayBetween = 200,
}: StaggerInteractionsT) {
  if (process.env.NODE_ENV === "development") {
    console.log("Running stagger interactions")
  }

  // TRIGGERS
  if (triggerOn == "load") {
    document.querySelectorAll(`[${dataAttrName}="${element}"]`).forEach((element, i) => {
      const delay = initialDelay + delayBetween * i
      const elementEl = element as HTMLElement
      setTimeout(function () {
        elementEl.click()
      }, delay)
    })
  } else if (triggerOn == "click") {
    const triggerEl = document.querySelector(`[${dataAttrName}="${trigger}"]`)
    if (!triggerEl) {
      console.warn(`Could not find element with selector [${dataAttrName}="${trigger}"]`)
      return `Could not find element with selector [${dataAttrName}="${trigger}"]`
    }
    triggerEl.addEventListener("click", () => {
      document.querySelectorAll(`[${dataAttrName}="${element}"]`).forEach((element, i) => {
        const delay = initialDelay + delayBetween * i
        const elementEl = element as HTMLElement
        setTimeout(function () {
          elementEl.click()
        }, delay)
      })
    })
  }
}
