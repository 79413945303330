import {safeParseInt} from "./safe"

const isCurrentPage = (link: HTMLAnchorElement) => {
  return link.pathname === window.location.pathname
}

const isNewTab = (link: HTMLAnchorElement) => {
  return link.getAttribute("target") === "_blank"
}

export default class PageTransitions {
  buffer: number
  transitionTrigger: HTMLElement
  transitionPageOutMS: number
  transitionPageInMS: number
  body: HTMLElement
  linksWithTransitions: HTMLAnchorElement[]

  constructor({buffer = 200}: {buffer?: number} = {}) {
    this.buffer = buffer

    this.transitionTrigger = document.querySelector(
      "[data-page-transition-role='trigger']",
    ) as HTMLElement

    // The time taken for the `Page OUT` transition to run
    this.transitionPageOutMS = safeParseInt(
      this.transitionTrigger?.dataset["pageTransitionPageOut"] ?? "",
      400,
    )

    // The time taken for the `Page IN` transition to run
    this.transitionPageInMS =
      safeParseInt(this.transitionTrigger?.dataset["pageTransitionPageIn"] ?? "", 400) + this.buffer

    this.body = document.body

    // Get a list of links that need transitions
    this.linksWithTransitions = [
      ...document.querySelectorAll("a:not([data-page-transition-role='no-transition-link'])"),
    ].filter((a: Element) => {
      const link = a as HTMLAnchorElement
      return (
        link.hostname === window.location.host &&
        !isCurrentPage(link) &&
        !isNewTab(link) &&
        link.getAttribute("href") !== null
      )
    }) as HTMLAnchorElement[]
    this.initOnPageLoad()
    this.initOnLinkClick()
    this.initBackButtonHandler()
  }

  // On Page Load
  initOnPageLoad() {
    if (this.transitionTrigger) {
      this.transitionTrigger.click()
      this.body.classList.add("no-scroll-transition")
      setTimeout(() => {
        this.body.classList.remove("no-scroll-transition")
      }, this.transitionPageInMS)
    }
  }

  // On Link Click
  initOnLinkClick() {
    this.linksWithTransitions.forEach((link) => {
      link.addEventListener("click", (event) => {
        if (this.transitionTrigger) {
          event.preventDefault()
          this.body.classList.add("no-scroll-transition")
          let transitionURL = link.getAttribute("href") as string & Location
          this.transitionTrigger.click()
          setTimeout(function () {
            window.location = transitionURL
          }, this.transitionPageOutMS)
        }
      })
    })
  }

  // On Back Button Tap
  initBackButtonHandler() {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload()
      }
    }
  }
}
